import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Gymnastic Day (this will take the place of our skill day every other
week)`}</em></p>
    <p>{`Strict Ring Pullups (pull as high as possible each rep) 6×6`}</p>
    <p>{`Strict Ring Dips (concentrate on full rom) 6×6`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Annie”`}</strong></p>
    <p>{`50-40-30-20-10 reps each:`}</p>
    <p>{`Double Unders`}</p>
    <p>{`Situps`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      